import React from 'react'

import PropTypes from 'prop-types'

import './question1.css'

const Question1 = ({ question = 'What types of cars do you sell?', answer = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non volutpat turpis. Mauris luctus rutrum mi ut rhoncus.' }) => {
  return (
    <div className="question1-container">
      <span className="question1-text heading3">{question}</span>
      <span className="bodySmall">{answer}</span>
    </div>
  )
}


Question1.propTypes = {
  answer: PropTypes.string,
  question: PropTypes.string,
}

export default Question1
