import React from 'react'

import {Helmet} from 'react-helmet'

import FeatureCard from '../components/feature-card'
import Question1 from '../components/question1'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Drill Desk</title>
        <meta property="og:title" content="DrillDesk"/>
      </Helmet>
      <div className="home-header">
        <header
          data-thq="thq-navbar"
          className="navbarContainer home-navbar-interactive"
        >
          <img
            src="/external/drilldesk-200h.png"
            alt="image"
            className="home-image"
          />
          <div data-thq="thq-navbar-nav" className="home-desktop-menu">
            <nav className="home-links">
              <span className="bodySmall">Home</span>
              <span className="home-nav22 bodySmall">Features</span>
              <span className="home-nav32 bodySmall">Simulations</span>
              {/*<span className="home-nav42 bodySmall">Pricing</span>*/}
              {/*<span className="home-nav52 bodySmall">Contact</span>*/}
            </nav>
            <div className="home-buttons"></div>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon socialIcons">
              <path
                d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="home-mobile-menu1 mobileMenu"
          >
            <div className="home-nav">
              <div className="home-top">
                <span className="logo">DRILLDESK</span>
                <div data-thq="thq-close-menu" className="home-close-menu">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="home-icon02 socialIcons"
                  >
                    <path
                      d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="home-links1">
                <span className="home-nav121 bodySmall">Home</span>
                <span className="home-nav221 bodySmall">Features</span>
                <span className="home-nav321 bodySmall">Simulations</span>
                {/*<span className="home-nav421 bodySmall">Pricing</span>*/}
                {/*<span className="home-nav521 bodySmall">Contact</span>*/}
              </nav>
              {/*<div className="home-buttons1">*/}
              {/*  <button className="buttonFlat">Login</button>*/}
              {/*  <button className="buttonFilled">Register</button>*/}
              {/*</div>*/}
            </div>
            <div>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="home-icon04 socialIcons"
              >
                <path
                  d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="home-icon06 socialIcons"
              >
                <path
                  d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="home-icon08 socialIcons"
              >
                <path
                  d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <div className="home-hero">
        <div className="heroContainer home-hero1">
          <div className="home-container1">
            <h1 className="home-hero-heading heading1">
              Enhance Team Preparedness with DrillDesk
            </h1>
            <span className="home-hero-sub-heading bodyLarge">
              <span>
                <span>
                  <span>
                    Comprehensive tools and simulations for annual table top
                    exercises
                  </span>
                  <span>
                      {' '}
                  </span>
                </span>
                <span>
                  <span>
                      {' '}
                  </span>
                  <span>
                      {' '}
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                      {' '}
                  </span>
                  <span>
                        {' '}
                  </span>
                </span>
                <span>
                  <span>
                      {' '}
                  </span>
                  <span>
                       {' '}
                  </span>
                </span>
              </span>
            </span>
            {/*<div className="home-btn-group">*/}
            {/*  <button className="buttonFilled">Get Started Now</button>*/}
            {/*  <button className="buttonFlat">Learn More →</button>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="home-features">
        <div className="featuresContainer">
          <div className="home-features1">
            <div className="home-container2">
              <span className="overline">
                <span>features</span>
                <br></br>
              </span>
              <h2 className="home-features-heading heading2">
                Key Features of DrillDesk
              </h2>
              <span className="home-features-sub-heading bodyLarge">
                <span>
                  <span>
                    <span>
                      Empowering your team with the tools and simulations needed
                      for comprehensive preparedness
                    </span>
                    <span>
                      {' '}
                    </span>
                  </span>
                  <span>
                    <span>
                      {' '}
                    </span>
                    <span>
                      {' '}
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      {' '}
                    </span>
                    <span>
                      {' '}
                    </span>
                  </span>
                  <span>
                    <span>
                      {' '}
                    </span>
                    <span>
                      {' '}
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="home-container3">
              <FeatureCard
                heading="Comprehensive Tools"
                subHeading="Access a wide range of tools for conducting various types of table top exercises"
              ></FeatureCard>
              <FeatureCard
                heading="Simulations"
                subHeading="Engage in realistic simulations to test team preparedness across different scenarios"
              ></FeatureCard>
              <FeatureCard
                heading="Enhanced Team Preparedness"
                subHeading="Improve team readiness in cybersecurity, business continuity, disaster recovery, and more"
              ></FeatureCard>
              <FeatureCard
                heading="Multi-disciplinary Focus"
                subHeading="Cover areas such as physical security, regulatory compliance and supply chain disruptions"
              ></FeatureCard>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="home-pricing"></div>*/}
      <div className="home-banner">
        <div className="bannerContainer home-banner1">
          <h1 className="home-banner-heading heading2">
            Empower Your Team with Comprehensive Preparedness Solutions
          </h1>
          <span className="home-banner-sub-heading bodySmall">
            <span>
              <span>
                <span>
                  DrillDesk offers a wide range of coaching tools and simulations to help
                  your team conduct and manage annual table top exercises
                  effectively. From cybersecurity to pandemic planning, our
                  platform covers various aspects of preparedness to ensure your
                  team is ready for any situation. With DrillDesk, you can
                  enhance your team&apos;s readiness across cybersecurity,
                  business continuity, disaster recovery, crisis management,
                  physical security, regulatory compliance and supply chain
                  disruptions.
                </span>
                <span>
                      {' '}
                </span>
              </span>
              <span>
                <span>
                      {' '}
                </span>
                <span>
                      {' '}
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                      {' '}
                </span>
                <span>
                      {' '}
                </span>
              </span>
              <span>
                <span>
                      {' '}
                </span>
                <span>
                      {' '}
                </span>
              </span>
            </span>
          </span>
          {/*<button className="buttonFilled">Learn More</button>*/}
        </div>
      </div>
      <div className="home-faq">
        <div className="faqContainer">
          <div className="home-faq1">
            <div className="home-container4">
              <span className="overline">
                <span>FAQ</span>
                <br></br>
              </span>
              <h2 className="home-text48 heading2">Common questions</h2>
              <span className="home-text49 bodyLarge">
                <span>
                  Here are some of the most common questions that we get.
                </span>
                <br></br>
              </span>
            </div>
            <div className="home-container5">
              <Question1
                answer="DrillDesk is a platform that provides comprehensive tools and simulations for conducting and managing annual table top exercises across various domains."
                question="What is DrillDesk?"
              ></Question1>
              <Question1
                answer="DrillDesk supports exercises in cybersecurity, business continuity, disaster recovery, crisis management, physical security, regulatory compliance, supply chain disruptions, and pandemic planning."
                question="What types of exercises can be conducted using DrillDesk?"
              ></Question1>
              <Question1
                answer="DrillDesk enhances team preparedness by providing realistic simulations and tools that help teams practice and improve their response strategies in various scenarios."
                question="How does DrillDesk enhance team preparedness?"
              ></Question1>
              <Question1
                answer="Yes, DrillDesk is designed to cater to the needs of organizations of all sizes and industries looking to enhance their preparedness and response capabilities."
                question="Is DrillDesk suitable for all types of organizations?"
              ></Question1>
              <Question1
                answer="Yes, DrillDesk offers customization options to tailor exercises and simulations to meet the specific needs and requirements of each organization."
                question="Can DrillDesk be customized to meet specific organizational requirements?"
              ></Question1>
            </div>
          </div>
        </div>
      </div>
      <div className="home-footer">
        <footer className="footerContainer home-footer1">
          <div className="home-container6">
            <img
              src="/external/drilldesk-200h.png"
              alt="image"
              className="home-image1"
            />
            <nav className="home-nav1">
              <span className="bodySmall">Home</span>
              <span className="home-nav222 bodySmall">Features</span>
              <span className="home-nav322 bodySmall">Simulations</span>
              {/*<span className="home-nav422 bodySmall">Pricing</span>*/}
              {/*<span className="home-nav522 bodySmall">Contact</span>*/}
            </nav>
          </div>
          <div className="home-separator"></div>
          <div className="home-container7">
            <span className="bodySmall home-text52">
              © 2024 Drill Desk, All Rights Reserved.
            </span>
            <div className="home-icon-group1">
              <a
                href="https://twitter.com/DrillDesk"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link"
              >
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="home-icon10 socialIcons"
                >
                  <path
                    d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/drilldesk/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link1"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon12 socialIcons"
                >
                  <path
                    d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/drilldesk"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link1"
              >
                <svg
                  className="home-icon14 socialIcons">
                  <path
                    d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"></path>
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Home
