import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { initGA, logPageView } from './components/analytics';

import './style.css';
import Home from './views/home';
import NotFound from './views/not-found';

const TrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView();
  }, [location]);

  return null; // This component doesn't render anything
};

const App = () => {
  return (
      <Router>
        <TrackPageViews /> {/* New component for tracking */}
        <Routes>
          <Route element={<Home />} exact path="/" />
          <Route element={<NotFound />} path="*/*" />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
      </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(<App />);
